import { parseWithZod } from '@conform-to/zod'
import {
  json,
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
  type MetaFunction,
} from '@remix-run/node'
import { useActionData, useSearchParams } from '@remix-run/react'
import { z } from 'zod'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { UserLogin } from '#app/components/onboarding/user-login.tsx'
import { checkHoneypot } from '#app/utils/honeypot.server.ts'
import { requireAnonymous } from '#server/auth/middleware.server.ts'
import { LoginSchema } from '#server/auth/schema.ts'
import { logIn, handleNewSession } from '#server/auth/session.server.ts'

export const meta: MetaFunction = () => {
  return [{ title: 'Login | Husthere' }]
}

export async function loader({ context }: LoaderFunctionArgs) {
  await requireAnonymous(context)
  return null
}

export async function action({ request, context }: ActionFunctionArgs) {
  await requireAnonymous(context)
  const formData = await request.formData()
  checkHoneypot(formData)

  const submission = await parseWithZod(formData, {
    schema: intent =>
      LoginSchema.transform(async (data, ctx) => {
        if (intent !== null) return { ...data, session: null }

        const session = await logIn(data)
        if (!session) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email or password',
          })
          return z.NEVER
        }

        return { ...data, session }
      }),
    async: true,
  })

  if (submission.status !== 'success' || !submission.value.session) {
    return json(
      { result: submission.reply({ hideFields: ['password'] }) },
      { status: submission.status === 'error' ? 400 : 200 },
    )
  }

  const { session, redirectTo } = submission.value
  return handleNewSession({ request, session, redirectTo })
}

export default function LoginPage() {
  const actionData = useActionData<typeof action>()
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo')

  return (
    <div className="flex min-h-full flex-col justify-center pb-32 pt-20">
      <UserLogin
        action="/login"
        lastResult={actionData?.result}
        redirectTo={redirectTo}
      />
    </div>
  )
}

export function ErrorBoundary() {
  return <GeneralErrorBoundary />
}
