import {
  type SubmissionResult,
  getFormProps,
  getInputProps,
  useForm,
} from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { Form, Link } from '@remix-run/react'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import { ProviderConnectionForm } from '#app/utils/connections.tsx'
import { useIsPending } from '#app/utils/misc.tsx'
import { providerNames } from '#server/auth/providers/provider.ts'
import { LoginSchema } from '#server/auth/schema.ts'
import { Field, ErrorList } from '../forms'
import { Separator } from '../ui/separator'
import { StatusButton } from '../ui/status-button'

interface Props {
  action: string
  lastResult?: SubmissionResult<string[]> | null
  redirectTo?: string | null
}

export function UserLogin({ action, lastResult, redirectTo }: Props) {
  const isPending = useIsPending()

  const [form, fields] = useForm({
    id: 'login-form',
    constraint: getZodConstraint(LoginSchema),
    defaultValue: { redirectTo },
    lastResult,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: LoginSchema })
    },
    shouldRevalidate: 'onBlur',
  })

  return (
    <div className="mx-auto w-full max-w-lg">
      <section className="flex flex-col gap-3 text-center">
        <h2 className="text-h2 text-nowrap tracking-tight">
          Welcome to Husthere
        </h2>
        <p className="text-body-lg text-muted-foreground">
          To get started, please sign in
        </p>
      </section>
      <section className="mx-auto w-full max-w-md px-8">
        <ul className="mt-5 flex flex-col gap-5 py-3">
          {providerNames.map(providerName => (
            <li key={providerName}>
              <ProviderConnectionForm
                type="Continue"
                providerName={providerName}
                redirectTo={redirectTo}
              />
            </li>
          ))}
        </ul>
      </section>
      <section className="mx-auto w-full max-w-md px-8">
        <div className="flex items-center gap-4">
          <Separator className="shrink" />
          <span className="text-body-lg text-muted-foreground">or</span>
          <Separator className="shrink" />
        </div>
      </section>
      <section className="mx-auto w-full max-w-md px-8">
        <Form method="POST" action={action} {...getFormProps(form)}>
          <HoneypotInputs />
          <Field
            labelProps={{ children: 'Email' }}
            inputProps={{
              ...getInputProps(fields.email, { type: 'email' }),
              autoFocus: true,
              className: 'lowercase placeholder:normal-case',
              autoComplete: 'email',
              placeholder: 'Enter email',
            }}
            errors={fields.email.errors}
          />
          <Field
            labelProps={{ children: 'Password' }}
            inputProps={{
              ...getInputProps(fields.password, {
                type: 'password',
              }),
              autoComplete: 'current-password',
              placeholder: 'Enter password',
            }}
            errors={fields.password.errors}
          />

          <div className="flex justify-between">
            <Link to="/forgot-password" className="text-body-xs mt-0.5">
              Forgot your password?
            </Link>
          </div>

          <input {...getInputProps(fields.redirectTo, { type: 'hidden' })} />
          <ErrorList errors={form.errors} id={form.errorId} />

          <div className="flex items-center justify-between gap-6 pt-3">
            <StatusButton
              className="w-full"
              status={isPending ? 'pending' : (form.status ?? 'idle')}
              type="submit"
              disabled={isPending}
            >
              Login
            </StatusButton>
          </div>
        </Form>

        <div className="flex items-center justify-center gap-2 pt-6">
          <span>Don't have an account?</span>
          <Link
            className="font-semibold text-blue-600 underline"
            to={
              redirectTo
                ? `/signup?${encodeURIComponent(redirectTo)}`
                : '/signup'
            }
          >
            Sign Up
          </Link>
        </div>
      </section>
    </div>
  )
}
